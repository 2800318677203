html,
body {
  height: 100%;
  scroll-behavior: smooth;
  background-color: #13020a;
  margin: 0;
  padding: 0;
}

body {
  color: #fff;
  background-image: url('../images/bg@2x.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;
}

div {
  box-sizing: border-box;
}

main {
  .boom-btn {
    position: absolute;
    left: 50%;
    margin-left: -113px;
    bottom: 350px;
    width: 226px;
    height: 226px;
  }

  .circle {
    position: absolute;
    left: 50%;
    top: 50%;
    background-size: cover;
    &.outside {
      width: 226px;
      height: 226px;
      margin-left: -113px;
      margin-top: -113px;
      mix-blend-mode: screen;
      background-image: url('../images/circle-outline@2x.png');
    }

    &.inside {
      width: 150px;
      height: 150px;
      margin-left: -75px;
      margin-top: -75px;
      mix-blend-mode: screen;
      background-image: url('../images/circle-inside@2x.png');
    }

    &.center {
      width: 36px;
      height: 50px;
      margin-left: -18px;
      margin-top: -25px;
      background-image: url('../images/circle-center@2x.png');
    }
  }

  .click-btn {
    position: absolute;
    left: 50%;
    margin-left: -250px;
    bottom: 100px;
    width: 501px;
    height: 230px;
    background-image: url('../images/clickBtn@2x.png');
    mix-blend-mode: screen;
    background-size: cover;
  }

  .greeting {
    position: absolute;
    font-size: 140px;
    left: 0;
    bottom: 50%;
    opacity: 0;
    width: 100%;
    text-align: center;
    user-select: none;
    transform: translate3d(0, -50%, 0);
    p {
      padding: 0;
      margin: 0;
      text-align: center;
      font-size: 90px;
    }
  }

  .success-box {
    position: absolute;
    top: 500px;
    left: 50%;
    width: 100%;
    margin-left: -50%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .progress-wrapper {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    position: relative;
    :global(.half-circle) {
      transform: rotateZ(135deg);
      transform-origin: 50% 100%;
      position: absolute;
      top: -20px;
      width: 288px;
      height: 144px;
      border-top-left-radius: 144px;
      border-top-right-radius: 144px;
      border: 5px solid #fff;
      border-bottom: 0;
    }
  }

  .progress-circle {
    position: absolute;
    width: 248px;
    height: 248px;
    padding: 20px;
    border-radius: 50%;
    border: 5px solid #981c59;
    font-size: 34px;
    top: 0;
    & .percentage {
      font-size: 60px;
    }
  }

  .welcome {
    background-size: cover;
    width: 251px;
    height: 56px;
    background-image: url('../images/welcome@2x.png');
    margin-bottom: 20px;
  }

  .your-rank {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 45px;
    & > .number {
      font-size: 160px;
      font-style: italic;
      margin: 0 10px;
      text-shadow: 10px 10px #981c59;
      &.small {
        font-size: 80px;
      }
    }
    & > .circle-text {
      font-size: 60px;
      font-weight: bold;
    }
  }

  .total-people {
    font-size: 34px;
    height: 82px;
    display: flex;
    width: 610px;
    background-position: left center;
    align-items: center;
    padding-left: 60px;
    padding-top: 10px;
    background-image: url('../images/flag-with-people-bg@2x.png');
    background-size: contain;
    mix-blend-mode: screen;
    background-repeat: no-repeat;
    &.shared {
      margin-top: 20px;
      width: 700px;
      height: 190px;
      align-items: start;
      justify-content: center;
      padding: 0;
      background-image: url('../images/flag-with-people-bottom@2x.png');
      & .number {
        align-self: baseline;
        justify-self: baseline;
      }
    }
    & .number {
      padding: 0 5px;
      font-size: 55px;
      font-weight: bold;
    }
  }
}

.sendGreeting {
  padding-top: 320px;
  display: flex;
  input {
    outline: none;
    border: 1px solid #fff;
    border-radius: 10px;
    font-size: 34px;
    padding: 10px;
    background: transparent;
    color: #fff;
    text-align: center;
    width: 400px;
  }
  button {
    outline: none;
    border: 1px solid #fff;
    color: #fff;
    font-size: 30px;
    background-color: transparent;
    border-radius: 10px;
    margin-left: 15px;
  }
}

.slogan2021 {
  background-size: cover;
  width: 750px;
  height: 588px;
  position: absolute;
  left: 50%;
  margin-left: -375px;
  top: 500px;
  background-image: url('../images/2021slogan.png');
}
.flame {
  position: fixed;
  bottom: 0;
  left: 50%;
  margin-left: -375px;
  background-size: cover;
  width: 750px;
  height: 453px;
  background-repeat: no-repeat;
  background-image: url('../images/flame.png');
}
